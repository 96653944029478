<template>
  <div style="padding: 20px">
    <el-form :model="queryForm" :inline="true" size="normal">
      <el-form-item label="交易日期">
        <el-date-picker
          v-model="queryForm.timeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          value-format="timestamp"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="交易单号">
        <el-input v-model="queryForm.order_id" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="交易渠道">
        <el-select v-model="queryForm.payment_channel" placeholder="请选择">
          <el-option label="微信支付" :value="1" />
          <el-option label="支付宝支付" :value="2" />
          <el-option label="账户余额支付" :value="3" />
          <el-option label="在途余额支付" :value="4" />
          <el-option label="首信易提现" :value="5" />
          <el-option label="首信易银行卡支付" :value="6" />
          <el-option label="杉德提现" :value="7" />
          <el-option label="杉德支付宝支付" :value="8" />
          <el-option label="杉德银行卡支付" :value="9" />
          <el-option label="银盛划转" :value="10" />
          <el-option label="银盛余额支付" :value="11" />
          <el-option label="银盛支付宝支付" :value="12" />
          <el-option label="银盛云闪付支付" :value="13" />
          <el-option label="银盛银行卡支付" :value="14" />
          <el-option label="快付通充值" :value="18" />
          <el-option label="快付通付款" :value="19" />
          <el-option label="杉德云账户" :value="99" />
        </el-select>
      </el-form-item>
      <el-form-item label="买家ucid">
        <el-input v-model="queryForm.buyer_id" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="卖家ucid">
        <el-input v-model="queryForm.seller_id" placeholder="请输入" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button type="info" @click="onClear">重置</el-button>
        <el-button type="success" @click="onExport">导出筛选结果</el-button>
      </el-form-item>
    </el-form>

    <div style="margin-bottom: 12px">
      <el-radio-group v-model="transaction_type" @change="handleChange">
        <el-radio-button :label="1">交易</el-radio-button>
        <el-radio-button :label="2">铸造/转让</el-radio-button>
        <el-radio-button :label="3">提现</el-radio-button>
        <el-radio-button :label="4">返现</el-radio-button>
      </el-radio-group>
    </div>
    <el-table :data="list" border stripe>
      <el-table-column
        v-for="col in columns[transaction_type - 1]"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
      >
      </el-table-column>
    </el-table>
    <div style="padding: 20px 0; text-align: right;">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { exportBalanceSnapshot, getTransactionList } from "@/api/nft-finance.js";
import dayjs from "dayjs";

export default {
  data() {
    const today = dayjs().startOf("day");
    const dayOfLastMonth = today.subtract(1, "month");
    const tradeColumn = [
      { id: "created_at", label: "交易时间" },
      { id: "order_id", label: "交易单号" },
      { id: "buyer_id", label: "买家ucid" },
      { id: "seller_id", label: "卖家ucid" },
      { id: "buyer_name", label: "买家姓名" },
      { id: "seller_name", label: "卖家姓名" },
      { id: "amount", label: "实际支付金额" },
      { id: "fee_amount", label: "5%手续费" },
      { id: "transaction_fee_amount", label: "上链费用" },
      { id: "work_name", label: "作品名称" },
      { id: "coupon_amount", label: "返利金额" },
      { id: "channel_fee", label: "收取支付手续费" },
      { id: "payment_channel", label: "交易渠道" },
    ];

    const transfeColumn = [
      { id: "created_at", label: "交易时间" },
      { id: "order_id", label: "交易单号" },
      { id: "buyer_id", label: "买家ucid" },
      { id: "buyer_name", label: "买家姓名" },
      { id: "amount", label: "实际支付金额" },
      { id: "work_name", label: "作品名称" },
      { id: "order_type", label: "类型" },
      { id: "payment_channel", label: "交易渠道" },
    ];

    const withdrawalColumn = [
      { id: "created_at", label: "交易时间" },
      { id: "order_id", label: "交易单号" },
      { id: "buyer_id", label: "买家ucid" },
      { id: "buyer_name", label: "买家姓名" },
      { id: "amount", label: "实际支付金额" },
      { id: "channel_fee", label: "收取支付手续费" },
      { id: "payment_channel", label: "交易渠道" },
    ];

    const cashbackColumn = [
      { id: "created_at", label: "交易时间" },
      { id: "order_id", label: "交易单号" },
      { id: "work_name", label: "作品名称" },
      { id: "buyer_id", label: "买家ucid" },
      { id: "buyer_name", label: "买家姓名" },
      { id: "amount", label: "实际支付金额" },
      { id: "coupon_amount", label: "返现金额" },
      { id: "coupon_type", label: "返现类型" },
      { id: "payment_channel", label: "交易渠道" },
    ];

    return {
      columns: [tradeColumn, transfeColumn, withdrawalColumn, cashbackColumn],
      queryForm: {
        timeRange: [dayOfLastMonth.unix() * 1000, today.unix() * 1000],
      },
      list: [],
      page: 1,
      page_size: 20,
      total: 0,
      transaction_type: 1,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    getPostData() {
      const { timeRange, ...rest } = this.queryForm;

      const postData = {
        ...rest,
        page: this.page,
        page_size: this.page_size,
        transaction_type: this.transaction_type,
      };

      if (timeRange && timeRange[0] && timeRange[1]) {
        const start = dayjs(timeRange[0]).format("YYYY-MM-DD");
        const end = dayjs(timeRange[1]).format("YYYY-MM-DD");

        postData["start_time"] = start;
        postData["end_time"] = end;
      }
      return postData;
    },
    async fetchList() {
      try {
        const postData = this.getPostData();
        const { data } = await getTransactionList(postData);
        this.list = data.data.items;
        this.total = data.data.total || 0;
      } catch (error) {
        console.log(error);
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.page_size = pageSize;
      this.fetchList();
    },
    handleChange() {
      this.page = 1;
      this.page_size = 20;
      this.fetchList();
    },
    onQuery() {
      this.page = 1;
      this.page_size = 20;
      this.fetchList();
    },
    onClear() {
      this.page = 1;
      this.page_size = 20;
      this.queryForm = {};
      this.fetchList();
    },
    async onExport() {
      try {
        const { page, page_size, ...rest } = this.getPostData();
        const { data } = await exportBalanceSnapshot(rest);
        if (data.data.url) {
          window.open(data.data.url);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
